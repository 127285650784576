import React from 'react';
import './App.css';
import MainPage from "./pages/MainPage";
import {Route, Routes} from "react-router";
import MenuItems from "./util/MenuItems";
import {BrowserRouter} from "react-router-dom";
import ScrollToTop from "./common/ScrollToTop";
import ErrorBoundary from "./common/ErrorBoundary";
import MainMenu from "./components/MainMenu";
import Footer from "./components/Footer";

function App() {
    return (
        <BrowserRouter>
            <MainMenu/>
            <ScrollToTop>
                <>
                    <ErrorBoundary>
                        <Routes>
                            <Route path='/' element={<MainPage/>}/>

                            {Object.values(MenuItems.menuItems).map(e =>
                                <Route key={e.url} path={e.url} element={<e.comp/>}/>)}

                        </Routes>
                    </ErrorBoundary>
                </>
            </ScrollToTop>

            <Footer/>
        </BrowserRouter>
    );
}

export default App;
