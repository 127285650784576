import {useEffect} from "react";
import {useLocation} from "react-router-dom";

type ScrollToTopProps = {
    children: JSX.Element
}

export default function ScrollToTop(props: ScrollToTopProps): JSX.Element {
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])
    return props.children
}
