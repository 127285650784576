import React from 'react';
import "./CreatorsPage.css";

export default function CreatorsPage() {
    return <section className="page">
        <section className="main">

            <h1 style={{
                marginBottom: "2em"
            }}>alkotók</h1>

            <section className="collaborator">
                <p>Koncepció:</p>
                <p>Igric Balázs</p>
            </section>

            <section className="collaborator">
                <p>Projektmenedzser:</p>
                <p>Sipos László</p>
            </section>

            <section className="collaborator">
                <p>Vállalati szakértő:</p>
                <p>Szklenár Ferenc</p>
            </section>

            <section className="collaborator">
                <p>Művészeti referens:</p>
                <p>Dr. Farkas István Péter</p>
            </section>

            <section className="collaborator">
                <p>Szoftveres kivitelezés:</p>
                <p>Kövesdán Gábor</p>
            </section>

            <section className="collaborator">
                <p>Hang:</p>
                <p>Weisz József</p>
            </section>

            <section className="collaborator">
                <p>Kép:</p>
                <p>Demeter László</p>
            </section>

            <section className="collaborator">
                <p>Kottagrafika:</p>
                <p>Szénási László</p>
            </section>

            <section className="collaborator">
                <p>Közreműködők:</p>
                <p>Duna Szimfonikus Zenekar, vezető karmester: Horváth Gábor, fuvola szóló: Demeter László</p>
            </section>
        </section>
    </section>;
}