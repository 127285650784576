import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import MenuItems from "../util/MenuItems";
import {isMobile} from "../util/isMobile";
import "./MainMenu.css";

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: Array<string>;

    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed',
        platform: string
    }>;

    prompt(): Promise<void>;
}

export default function MainMenu() {
    const [activeItem, setActiveItem] = useState<string | null>(null)
    const [navbarShow, setNavbarShow] = useState<boolean>(false)
    const navigate = useNavigate()
    const location = useLocation()


    const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
    const [installed, setInstalled] = useState<Boolean>(false);
    const [pwa, setPwa] = useState<Boolean>(false);

    useEffect(() => {
        const refreshData = async () => {
            window.addEventListener('beforeinstallprompt', (e) => {
                // @ts-ignore
                setDeferredPrompt(e);
                e.preventDefault();
            });

            window.addEventListener('appinstalled', () => {
                setInstalled(true);
            });

            if ('getInstalledRelatedApps' in navigator) {
                // @ts-ignore
                navigator.getInstalledRelatedApps().then((relatedApps: Array<any>) => {
                    setInstalled(relatedApps.length > 0);
                });
            }

            setPwa((window.matchMedia('(display-mode: standalone)').matches) || document.referrer.includes('android-app://'));
        }

        refreshData()
    }, [])

    const installApp = () => {
        if (deferredPrompt !== null) {
            deferredPrompt.prompt().then(() => setDeferredPrompt(null));
        }
    };

    const setActiveMenuItem = (item: string) => {
        setActiveItem(item)
        navigate(MenuItems.menuItems[item].url);
    }

    useEffect(() => {
        const item = Object.keys(MenuItems.menuItems).filter(k => location.pathname.startsWith(MenuItems.menuItems[k].url))[0]
        if (item) {
            setActiveItem(item)
        }
    }, [])

    const classForItem = (item: string) =>
        (activeItem === item) ? "nav-item active" : "nav-item"

    const navbarClass = () =>
        (navbarShow) ? "collapse navbar-collapse show" : "collapse navbar-collapse"

    return <nav className="navbar fixed-top navbar-expand-lg">
        <div className="container-fluid">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    onClick={() => setNavbarShow(!navbarShow)}
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={navbarClass()} id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li key="kezdőlap" className={classForItem("kezdőlap")}>
                        <a className="nav-link" href="/"
                           onClick={() => setActiveMenuItem("kezdőlap")}>kezdőlap</a>
                    </li>
                    {Object.values(MenuItems.menuItems)
                        .filter(m => m.url != "/")
                        .map(m =>
                            <li key={m.title} className={classForItem(m.title)}>
                                <a className="nav-link" href={m.url}
                                   onClick={() => setActiveMenuItem(m.title)}>{m.title}</a>
                            </li>
                        )}
                </ul>
            </div>

            {(isMobile() && !pwa) &&
                (installed ?
                    <button className="btn btn-light install" disabled={true}>Telepítve <i
                        className="fa-solid fa-check"></i></button> :
                    <button className="btn btn-light install" onClick={installApp}>Telepítés <i
                        className="fa-solid fa-download"></i></button>)
            }
        </div>
    </nav>;
}