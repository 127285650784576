import React from 'react';

export default function ContactPage() {
    return <section className="page">
        <section className="main">

            <h1 style={{
                marginBottom: "2em"
            }}>kapcsolat</h1>

            <p>Írja még észrevételeit, kérdéseit!</p>

            <p>
                Az alábbi címen készséggel állunk rendelkezésére:
            </p>

            <p><a href="mailto:info@interaktivhangverseny.hu">info@interaktivhangverseny.hu</a></p>

        </section>
    </section>;
}