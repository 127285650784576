import React, {useEffect, useState} from 'react';

export default function MainPage() {

    return <section className="page">
        <section className="main">
            <h1>tisztelt látogató<span className="normal">!</span></h1>

            <p>Az <em>Interaktív hangversenyterem</em> a <a href="https://dunaszimfonikusok.hu/" target="_blank"
                                                            rel="noreferrer">Duna Szimfonikus
                Zenekar</a> és a Pécsi Tudományegyetem közös közönségnevelő projektje, ötletgazdája
                és művészeti koordinátora Igric Balázs, a zenekar trombitaművésze és az egyetem művészeti karának
                doktorandusza.
            </p>

            <p>Célunk, hogy a digitalizáció adta lehetőségekkel a zenei előadást közelebb vigyük a befogadóhoz.
                A szoftver fiataloknak készült az általános iskolai és középiskolai ének-zene tananyaggal összhangban,
                de azoknak a felnőtt zenebarátoknak is szeretettel ajánljuk, akik nyitottak a hagyományos
                közönségszerepből
                történő kilépésre. </p>

            <p>Virtuális hangversenytermünket kreatív funkciókkal láttuk el. Ezek segítségével Ön megismerheti a
                szimfonikus zenekar munkáját, megérthet alapvető zenei folyamatokat és akusztikai jelenségeket,
                illetve bepillanthat a kulisszák mögé. A hagyományos videókról szabadon válthat kisebb hangszeres
                csoportok – zenekari szólamok – felvételeire, a zenehallgatás megszakítása nélkül. A művészek
                tevékenységét is átélheti az egyes szólamkották követésével és a kiemelt hangszerspecifikus hangsávok
                által.</p>

            <p>A zenekari videófelvételeket, szólam- és kottanézeteket feliratos narráció kíséri. A kísérőszövegekkel
                olyan háttérinformációkat szeretnénk megosztani, melyek átadására hagyományos formában, koncert
                közben nem lenne lehetőség.</p>

            <p>Tartalmas időtöltést kívánunk Önnek!</p>

            <p>Az Interaktív hangversenyterem alkotói</p>

            <p><em>Az Interaktív Hangversenyterem az Innovációs és Technológiai Minisztérium Kooperatív Doktori
                Program doktori hallgatói ösztöndíj programjának a Nemzeti Kutatási, Fejlesztési és Innovációs
                Alapból finanszírozott szakmai támogatásával készült.</em></p>

            <p><img className="logo small" alt="NKFI logó" src="/img/nkfi.png"/></p>

        </section>
    </section>
}