import MainPage from "../pages/MainPage";
import ConcertHallPage from "../pages/ConcertHallPage";
import SupportPage from "../pages/SupportPage";
import CreatorsPage from "../pages/CreatorsPage";
import ContactPage from "../pages/ContactPage";

export default class MenuItems {
    static ACTIVE_MENU_KEY = "active_menuitem";

    static MAIN = "kezd\u0151lap";

    static CONCERT_HALL = "hangversenyterem";

    static SUPPORT = "támogatók";

    static CREATORS = "alkotók";

    static CONTACT = "kapcsolat";

    static menuItems = {
        [MenuItems.MAIN]: {
            title: MenuItems.MAIN,
            url: "/",
            comp: MainPage
        },
        [MenuItems.CONCERT_HALL]: {
            title: MenuItems.CONCERT_HALL,
            url: "/hangverseny",
            comp: ConcertHallPage
        },
        [MenuItems.SUPPORT]: {
            title: MenuItems.SUPPORT,
            url: "/tamogatok",
            comp: SupportPage
        },
        [MenuItems.CREATORS]: {
            title: MenuItems.CREATORS,
            url: "/alkotok",
            comp: CreatorsPage
        },
        [MenuItems.CONTACT]: {
            title: MenuItems.CONTACT,
            url: "/kapcsolat",
            comp: ContactPage
        }
    };

    static defaultItem = MenuItems.menuItems[MenuItems.MAIN];

    static searchByUrl = (url: string) => {
        for (const e of Object.values(MenuItems.menuItems)) {
            if (e.url === url)
                return e.title;
        }
        return null;
    }
}
