import React from 'react';

export default function SupportPage() {
    return <section className="page">
        <section className="main">

            <h1 style={{
                marginBottom: "2em"
            }}>támogatóink</h1>

            <p><img className="logo" alt="Nemzeti Kutatási, Fejlesztési és Innovációs Hivatal logója" src="/img/NIH_logo_allo_szeles.jpg"/></p>

            <p><img className="logo" alt="Pécsi Tudományegyetem logója" src="/img/PTE.png"/></p>

            <p><img className="logo" alt="Duna Szimfonikus Zenekar logója" src="/img/DUNA-LOGO-2022-feher.jpg"/></p>

            <p><img className="logo" alt="Nemzeti Kulturális Alap logója" src="/img/nka.jpg"/></p>

        </section>
    </section>;
}