import React, {useEffect, useRef, useState} from 'react';
import "./ConcertHallPage.css";
import axios from "axios";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Switch from "react-switch";

type Track = {
    title: string,
    videoUrl: string,
    tabUrl: string | undefined,
    caption: string | undefined,
    tabCaption: string | undefined
}

type Piece = {
    title: string,
    tracks: Array<Track>
}

export default function ConcertHallPage() {
    const [pieces, setPieces] = useState<Array<Piece>>([]);
    const [activePiece, setActivePiece] = useState<Piece | null>(null);
    const [activeTrack, setActiveTrack] = useState<Track | null>(null)
    const [tabView, setTabView] = useState<boolean>(false);
    const [subEnabled, setSubEnabled] = useState<boolean>(false);
    const [error, setError] = useState<any>(null)
    const videoRef = useRef<HTMLVideoElement>(null);

    const setPiece = (title: string) => {
        const video = videoRef.current!;
        video.currentTime = 0;
        const piece = pieces?.filter(t => t.title === title)[0] || null;
        setActivePiece(piece);
        setActiveTrack(piece.tracks[0]);
        setTabView(false);

    }

    const setTrack = (title: string) => {
        const track = activePiece?.tracks.filter(t => t.title === title)[0] || null;
        if (activeTrack?.title === title) {
            return;
        }
        setActiveTrack(track);
        setTrackUrl(track!!.videoUrl);
        setTabView(false);
    }

    const setTrackUrl = (url: string) => {
        const video = videoRef.current!;
        const currentTime = video.currentTime || 0;
        video.addEventListener("loadeddata", () => {
            video.currentTime = currentTime;
        })
        video.setAttribute('src', url);
        video.load();
        video.play();
    }

    const trackToPlay = () => (tabView ? activeTrack?.tabUrl : activeTrack?.videoUrl) || ""

    const caption = () => !tabView ? activeTrack?.caption : activeTrack?.tabCaption

    const toggleTabView = (val: boolean) => {
        setTabView(val);
        setTrackUrl(trackToPlay());
    }

    const toggleSubs = (val: boolean) => {
        setSubEnabled(val);
        setTrackUrl(trackToPlay());
    }

    useEffect(() => {
        const refreshData = async () => {
            try {
                const response = await axios.get(`/media/tracks.json`);
                console.log(response.data);
                setPieces(response.data);
                setActivePiece(response.data[0] || null);
                setActiveTrack(response.data[0]?.tracks[0] || null);
            } catch (error) {
                setError(error)
            }
        }

        refreshData()
    }, [])

    return (<section className="page">

        <section className="video-container">
            {pieces?.length > 0 ? (
                <>
                    <section className="controls">
                        <section className="group">
                            <div className="label">Zenemű:</div>
                            <Dropdown options={pieces?.map(i => i.title)} onChange={i => setPiece(i.value)}
                                      value={activePiece?.title} placeholder="Válasszon egy darabot!"/>
                        </section>
                        <section className="group">
                            <div className="label">Videósáv:</div>
                            <Dropdown options={activePiece?.tracks.map(i => i.title) || []}
                                      onChange={i => setTrack(i.value)}
                                      value={activeTrack?.title} placeholder="Válasszon egy videósávot!"/>
                        </section>
                        <section className="group">
                            <div className="label">Kottanézet:</div>
                            <Switch
                                onChange={toggleTabView}
                                checked={tabView}
                                className="react-switch"
                            />
                            {caption() &&
                                <>
                                    <span>&nbsp;</span>
                                    <div className="label"> Feliratok:</div>
                                    <Switch
                                        onChange={toggleSubs}
                                        checked={subEnabled}
                                        className="react-switch"
                                    />
                                </>
                            }

                        </section>
                    </section>


                    <section className="video">
                        <video
                            ref={videoRef}
                            id="video"
                            controls
                            src={trackToPlay()}
                            autoPlay
                            playsInline>
                            Sajnáljuk, a böngészője nem támogatja videók lejátszását.

                            {subEnabled &&
                                <track label="Kommentár" kind="subtitles" src={caption()} default/>
                            }

                        </video>
                    </section>
                </>
            ) : ""}
        </section>
    </section>)
}