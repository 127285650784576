import React from 'react';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, info) {
        this.setState({hasError: true});
        const data = {
            error,
            info
        };
        console.log(data);
    }

    render() {
        if (this.state.hasError) {
            return <section className="section">
                <article className="message is-danger">
                    <div className="message-header">
                        <p>Váratlan hiba</p>
                        <button type="button"
                                className="delete"
                                aria-label="delete"
                                onClick={() => this.props.history.push("/")}/>
                    </div>
                    <div className="message-body">
                        Váratlan hiba történt. Kérjük próbálja újra, vagy ha a hiba fennáll,
                        lépjen kapcsolatba az üzemeltetővel.
                    </div>
                </article>
            </section>

        }
        return this.props.children;
    }
}
